<template>
  <section>
    <section class="top">
      <div>
        <p class="res_title">
          Result
        </p>
        <p class="res_subtitle">
          List Of Results
        </p>
      </div>
      <div>
        <div class="top_search">
          <div v-if="(radioGroup === 'class')">
            <VSelect
              v-model="filterType"
              :hide-details="true"
              :items="students"
              item-text="class"
              item-value="class"
              label="Sort By Class"
              solo
              flat
            />
          </div>
          <div v-if="(radioGroup === 'session')">
            <VSelect
              v-model="filterType"
              :hide-details="true"
              :items="students"
              item-text="session"
              item-value="session"
              label="Sort By Session"
              solo
              flat
            />
          </div>
          <div v-if="(radioGroup === 'term')">
            <VSelect
              v-model="filterType"
              :hide-details="true"
              :items="students"
              item-text="term"
              item-value="term"
              label="Sort By Term"
              solo
              flat
            />
          </div>
          <div v-if="(radioGroup === 'id')">
            <VTextField
              v-model="filterType"
              :hide-details="true"
              label="Search By ID"
              append-icon="search"
              solo
              flat
            />
          </div>
          <div v-if="(radioGroup === 'name')">
            <VTextField
              v-model="filterType"
              :hide-details="true"
              label="Search By Name"
              append-icon="search"
              solo
              flat
            />
          </div>
        </div>
      </div>
      <div class="top_btn">
        <VBtn
          icon
          large
          class="shadow mr-0"
          color="white"
          @click="filterModal = true"
        >
          <VIcon>
            filter_list
          </VIcon>
        </VBtn>
        <VBtn
          round
          large
          class="shadow"
          color="white"
          to="result/new"
        >
          New Result
        </VBtn>
      </div>
    </section>
    <section class="content">
      <div v-if="(radioGroup === 'name')">
        <div class="res_grid">
          <!-- Start Looping Data -->
          <div
            v-for="student in filteredStudentsByName"
            :key="student.id"
          >
            <VCard class="res_card shadow">
              <VList three-line>
                <VListTile>
                  <VListTileAvatar color="primary">
                    <span class="white--text">
                      <b> {{ student.name.charAt(0) }} </b>
                    </span>
                  </VListTileAvatar>
                  <VListTileContent>
                    <VListTileTitle> {{ student.name }} </VListTileTitle>
                    <VListTileSubTitle class="subtxt">
                      {{ student.admNo }}
                    </VListTileSubTitle>
                    <VListTileSubTitle class="subtxt">
                      <VChip
                        small
                        color="primary"
                        disabled
                        class="white--text ma-0"
                      >
                        {{ student.term }}
                      </VChip>
                      <VChip
                        small
                        color="primary"
                        disabled
                        class="white--text ma-0 ml-1"
                      >
                        {{ student.session }}
                      </VChip>
                    </VListTileSubTitle>
                  </VListTileContent>
                  <VListTileAction>
                    <VListTileActionText class="subtxt">
                      CL : {{ student.class }}
                    </VListTileActionText>

                    <VBtn
                      icon
                      color="white"
                      @click="viewResult(student.id)"
                    >
                      <VIcon color="secondary">
                        visibility
                      </VIcon>
                    </VBtn>
                  </VListTileAction>
                </VListTile>
              </VList>
            </VCard>
          </div>
        </div>
      </div>
      <div v-if="(radioGroup === 'id')">
        <div class="res_grid">
          <!-- Start Looping Data -->
          <div
            v-for="student in filteredStudentsByID"
            :key="student.id"
          >
            <VCard class="res_card shadow">
              <VList three-line>
                <VListTile>
                  <VListTileAvatar color="primary">
                    <span class="white--text">
                      <b> {{ student.name.charAt(0) }} </b>
                    </span>
                  </VListTileAvatar>
                  <VListTileContent>
                    <VListTileTitle> {{ student.name }} </VListTileTitle>
                    <VListTileSubTitle class="subtxt">
                      {{ student.admNo }}
                    </VListTileSubTitle>
                    <VListTileSubTitle class="subtxt">
                      <VChip
                        small
                        color="primary"
                        disabled
                        class="white--text ma-0"
                      >
                        {{ student.term }}
                      </VChip>
                      <VChip
                        small
                        color="primary"
                        disabled
                        class="white--text ma-0 ml-1"
                      >
                        {{ student.session }}
                      </VChip>
                    </VListTileSubTitle>
                  </VListTileContent>
                  <VListTileAction>
                    <VListTileActionText class="subtxt">
                      CL : {{ student.class }}
                    </VListTileActionText>

                    <VBtn
                      icon
                      color="white"
                      @click="viewResult(student.id)"
                    >
                      <VIcon color="secondary">
                        visibility
                      </VIcon>
                    </VBtn>
                  </VListTileAction>
                </VListTile>
              </VList>
            </VCard>
          </div>
        </div>
      </div>
      <div v-if="(radioGroup === 'class')">
        <div class="res_grid">
          <!-- Start Looping Data -->
          <div
            v-for="student in filteredStudentsByClass"
            :key="student.id"
          >
            <VCard class="res_card shadow">
              <VList three-line>
                <VListTile>
                  <VListTileAvatar color="primary">
                    <span class="white--text">
                      <b> {{ student.name.charAt(0) }} </b>
                    </span>
                  </VListTileAvatar>
                  <VListTileContent>
                    <VListTileTitle> {{ student.name }} </VListTileTitle>
                    <VListTileSubTitle class="subtxt">
                      {{ student.admNo }}
                    </VListTileSubTitle>
                    <VListTileSubTitle class="subtxt">
                      <VChip
                        small
                        color="primary"
                        disabled
                        class="white--text ma-0"
                      >
                        {{ student.term }}
                      </VChip>
                      <VChip
                        small
                        color="primary"
                        disabled
                        class="white--text ma-0 ml-1"
                      >
                        {{ student.session }}
                      </VChip>
                    </VListTileSubTitle>
                  </VListTileContent>
                  <VListTileAction>
                    <VListTileActionText class="subtxt">
                      CL : {{ student.class }}
                    </VListTileActionText>

                    <VBtn
                      icon
                      color="white"
                      @click="viewResult(student.id)"
                    >
                      <VIcon color="secondary">
                        visibility
                      </VIcon>
                    </VBtn>
                  </VListTileAction>
                </VListTile>
              </VList>
            </VCard>
          </div>
        </div>
      </div>
      <div v-if="(radioGroup === 'term')">
        <div class="res_grid">
          <!-- Start Looping Data -->
          <div
            v-for="student in filteredStudentsByTerm"
            :key="student.id"
          >
            <VCard class="res_card shadow">
              <VList three-line>
                <VListTile>
                  <VListTileAvatar color="primary">
                    <span class="white--text">
                      <b> {{ student.name.charAt(0) }} </b>
                    </span>
                  </VListTileAvatar>
                  <VListTileContent>
                    <VListTileTitle> {{ student.name }} </VListTileTitle>
                    <VListTileSubTitle class="subtxt">
                      {{ student.admNo }}
                    </VListTileSubTitle>
                    <VListTileSubTitle class="subtxt">
                      <VChip
                        small
                        color="primary"
                        disabled
                        class="white--text ma-0"
                      >
                        {{ student.term }}
                      </VChip>
                      <VChip
                        small
                        color="primary"
                        disabled
                        class="white--text ma-0 ml-1"
                      >
                        {{ student.session }}
                      </VChip>
                    </VListTileSubTitle>
                  </VListTileContent>
                  <VListTileAction>
                    <VListTileActionText class="subtxt">
                      CL : {{ student.class }}
                    </VListTileActionText>

                    <VBtn
                      icon
                      color="white"
                      @click="viewResult(student.id)"
                    >
                      <VIcon color="secondary">
                        visibility
                      </VIcon>
                    </VBtn>
                  </VListTileAction>
                </VListTile>
              </VList>
            </VCard>
          </div>
        </div>
      </div>
      <div v-if="(radioGroup === 'session')">
        <div class="res_grid">
          <!-- Start Looping Data -->
          <div
            v-for="student in filteredStudentsBySession"
            :key="student.id"
          >
            <VCard class="res_card shadow">
              <VList three-line>
                <VListTile>
                  <VListTileAvatar color="primary">
                    <span class="white--text">
                      <b> {{ student.name.charAt(0) }} </b>
                    </span>
                  </VListTileAvatar>
                  <VListTileContent>
                    <VListTileTitle> {{ student.name }} </VListTileTitle>
                    <VListTileSubTitle class="subtxt">
                      {{ student.admNo }}
                    </VListTileSubTitle>
                    <VListTileSubTitle class="subtxt">
                      <VChip
                        small
                        color="primary"
                        disabled
                        class="white--text ma-0"
                      >
                        {{ student.term }}
                      </VChip>
                      <VChip
                        small
                        color="primary"
                        disabled
                        class="white--text ma-0 ml-1"
                      >
                        {{ student.session }}
                      </VChip>
                    </VListTileSubTitle>
                  </VListTileContent>
                  <VListTileAction>
                    <VListTileActionText class="subtxt">
                      CL : {{ student.class }}
                    </VListTileActionText>

                    <VBtn
                      icon
                      color="white"
                      @click="viewResult(student.id)"
                    >
                      <VIcon color="secondary">
                        visibility
                      </VIcon>
                    </VBtn>
                  </VListTileAction>
                </VListTile>
              </VList>
            </VCard>
          </div>
        </div>
      </div>
    </section>
    <VDialog
      v-model="filterModal"
      persistent
      max-width="290"
    >
      <VCard class="elevation-20 filter_card">
        <div class="filter_card_container">
          <p>
            Filter Results
          </p>
          <VRadioGroup
            v-model="radioGroup"
            :hide-details="true"
          >
            <VRadio
              value="name"
              color="primary"
            >
              <template v-slot:label>
                <div class="filter_txt">
                  Search By Name
                </div>
              </template>
            </VRadio>

            <VRadio
              value="id"
              color="primary"
            >
              <template v-slot:label>
                <div class="filter_txt">
                  Search By FLA ID
                </div>
              </template>
            </VRadio>

            <VRadio
              value="class"
              color="primary"
            >
              <template v-slot:label>
                <div class="filter_txt">
                  Sort By Class
                </div>
              </template>
            </VRadio>

            <VRadio
              value="term"
              color="primary"
            >
              <template v-slot:label>
                <div class="filter_txt">
                  Sort By Term
                </div>
              </template>
            </VRadio>

            <VRadio
              value="session"
              color="primary"
            >
              <template v-slot:label>
                <div class="filter_txt">
                  Sort By Session
                </div>
              </template>
            </VRadio>
          </VRadioGroup>
        </div>
        <VCardActions>
          <VSpacer />
          <div class="pt-2 pr-2 pl-0 pb-2">
            <VBtn
              class="ma-0"
              color="primary"
              flat
              round
              depressed
              @click="filterModal = false"
            >
              Done
            </VBtn>
          </div>
        </VCardActions>
      </VCard>
    </VDialog>
    <VSnackbar
      v-model="snackbar"
      color="secondary"
      :timeout="3000"
      :multi-line="true"
    >
      <span>{{ snackbarText }} <strong>SELECTED</strong></span>
      <VBtn
        color="primary"
        depressed
        style="width:100px;"
        round
        @click="snackbar = false"
      >
        Close
      </VBtn>
    </VSnackbar>
    <VDialog
      v-model="isLoading"
      width="300"
      persistent
    >
      <VCard
        v-if="loadStatus === 'loading'"
        class="notification_card elevation-20"
      >
        <p>Loading</p>
        <span>Fetching Data, Please Wait...</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'netfail'"
        class="notification_card elevation-20"
      >
        <p>Internet Issue</p>
        <span>Retrying In {{ retry }} Seconds</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="red"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'fail'"
        class="notification_card elevation-20"
      >
        <p>Error</p>
        <span>An Error Occured</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="reloadPage()"
          >
            Reload
          </VBtn>
        </div>
      </VCard>
    </VDialog>
  </section>
</template>

<script>
import axios from 'axios';
import api from '../../conf/api';

export default {
  data: () => ({
    isLoading: true,
    loadStatus: '',
    retry: 0,
    filterModal: false,
    snackbar: false,
    snackbarText: '',
    radioGroup: 'name',
    filterType: '',
    students: [],
  }),
  computed: {
    filteredStudentsByName() {
      return this.students.filter(student => student.name.toLowerCase()
        .match(this.filterType.toLowerCase()));
    },
    filteredStudentsByID() {
      return this.students.filter(student => student.admNo.toLowerCase()
        .match(this.filterType.toLowerCase()));
    },
    filteredStudentsByClass() {
      return this.students.filter(student => student.class.toLowerCase()
        .match(this.filterType.toLowerCase()));
    },
    filteredStudentsByTerm() {
      return this.students.filter(student => student.term.toLowerCase()
        .match(this.filterType.toLowerCase()));
    },
    filteredStudentsBySession() {
      return this.students.filter(student => student.session.toLowerCase()
        .match(this.filterType.toLowerCase()));
    },
  },
  watch: {
    // Change input type based on radio BTN
    radioGroup(v) {
      if (v) {
        this.filterType = '';
      }
      if (v === 'name') {
        this.snackbar = true;
        this.snackbarText = 'Search By Name';
      }
      if (v === 'id') {
        this.snackbar = true;
        this.snackbarText = 'Search By By ID';
      }
      if (v === 'class') {
        this.snackbar = true;
        this.snackbarText = 'Sort By Class';
      }
      if (v === 'term') {
        this.snackbar = true;
        this.snackbarText = 'Sort By Term';
      }
      if (v === 'session') {
        this.snackbar = true;
        this.snackbarText = 'Sort By Session';
      }
    },
  },
  async beforeMount() {
    try {
      this.loadStatus = 'loading';
      await axios.get(`${api}/result/all`)
        .then((res) => {
          this.isLoading = false;
          if (res.data !== '') {
            this.students = res.data.data;
          }
        });
    } catch (e) {
      if (e.code === 'auth/network-request-failed') {
        this.loadStatus = 'netfail';
        this.retry = 10;
        setInterval(() => {
          this.retry -= 1;
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 9500);
      } else {
        this.loadStatus = 'fail';
      }
    }
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    viewResult(id) {
      setTimeout(() => {
        this.$router.push(`/result/${id}`);
      }, 150);
    },
  },

};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Result/index';
</style>
